<template>
    <div>
        <role-view :role="roleComponent" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import RoleView from "@/components/roles/RoleView.vue";

export default {
    name: "CreateUpdateRole",
    components: {
        RoleView
    },
    data: () => ({
        isLoading: false,
        loaderInstance: null,
        roleComponent: {
            slug: "",
            name: "",
            description: "",
            permissions: [
                {
                    name: "",
                    slug: ""
                }
            ]
        }
    }),
    computed: { ...mapGetters("control", ["backgroundColor"]) },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("role:Get", "/app/roles"))) return;
        const slug = this.$route.params.slug;
        await this.getBaseItem(slug);
    },
    methods: {
        ...mapActions("roles", ["getRoleBySlug"]),
        async getBaseItem(slug) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                this.roleComponent = await this.getRoleBySlug({ slug });
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        }
    }
};
</script>
